import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {AsyncPipe, NgIf} from '@angular/common';
import {IconWrapperComponent} from '@widgets/icons/components';
import {LinkComponent} from '@widgets/buttons/components';
import {AccountService} from '@services/account';
import {LoadingService} from '@core/backend/services/loading.service';

@Component({
  selector: 'ec-show-hide-money',
  standalone: true,
  imports: [
    AsyncPipe,
    IconWrapperComponent,
    LinkComponent,
    NgIf
  ],
  templateUrl: './show-hide-money.component.html',
  styleUrl: './show-hide-money.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowHideMoneyComponent {
  withMargin = input();

  constructor(
    private readonly accountService: AccountService,
    public readonly loadingService: LoadingService,
  ) {}

  getShowMoney(): boolean {
    return !!this.accountService.account.show_money;
  }

  toggleMoney(): void {
    this.accountService.updateMoneyVisibility().subscribe(() => {
      location.reload();
    });
  }
}
