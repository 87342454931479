import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Icon } from '@widgets/icons';
import { LoadingService } from '@core/backend/services/loading.service';
import { IconWrapperComponent } from '../../../icons/components/icon-wrapper/icon-wrapper.component';
import { RouterLink } from '@angular/router';
import { LinkComponent } from '../../../buttons/components/link/link.component';
import { NgIf, AsyncPipe } from '@angular/common';
import {ResponsiveService} from '@services/responsive';
import {ShowHideMoneyComponent} from '@widgets/money/components/show-hide-money/show-hide-money.component';

@Component({
    selector: 'page-title',
    template: `
    <div class="d-xl-none d-flex justify-content-between align-items-center mb-4">
      <a ec-link size="md" *ngIf="backUrl" [routerLink]="backUrl">
        <span ec-icon-wrapper icon="arrow-left" size="md"></span>
        Back
      </a>

      @if (hasHideMoney) {
        <ec-show-hide-money [withMargin]="!!backUrl"></ec-show-hide-money>
      }
    </div>

    <ng-content select="ec-account-sidebar-projects"></ng-content>

    <div class="wrapper flex-xl-row" [class.flex-column]="showActionsOnTouch">
      <h1 [class.subtitle]="subtitle" class="d-flex">
        <a
          [routerLink]="backUrl"
          *ngIf="backUrl"
          ec-icon-wrapper
          icon="arrow-left"
          size="md"
          class="back-icon d-xl-block d-none"
        ></a>
        <ng-content></ng-content>
      </h1>

      <div
        *ngIf="icon"
        class="icon"
        size="lg"
        touchSize="md"
        (click)="iconClick.emit()"
        ec-icon-wrapper
        [icon]="icon"
      ></div>

      <span class="chip-status {{ badge.replaceAll(' ', '') }}" *ngIf="badge && !(isTouch$ | async)">
        {{ badge }}
      </span>

      <div
        class="d-none d-xl-flex ms-xl-auto ms-0 mt-xl-2 mt-4 align-self-start"
        [class.show-on-touch]="showActionsOnTouch"
        [class.action-block-100-on-touch]="showActionsOnTouch"
      >
        <ng-content select="[actionContent]"></ng-content>

        @if (hasHideMoney) {
          <ec-show-hide-money [withMargin]="!!backUrl"></ec-show-hide-money>
        }
      </div>
    </div>
    <div *ngIf="caption" class="caption d-flex" [class.flex-xl-column]="captionFlexDirectionColumn" [class.has-back]="!!backUrl">
      <div>{{ caption }}</div>
      <span class="mt-2 chip-status {{ badge.replaceAll(' ', '') }}" *ngIf="badge && (isTouch$ | async)">
        {{ badge }}
      </span>
      <ng-content select="page-action"></ng-content>
    </div>
  `,
    styleUrls: ['./page-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
  imports: [
    NgIf,
    LinkComponent,
    RouterLink,
    IconWrapperComponent,
    AsyncPipe,
    ShowHideMoneyComponent,
  ],
})
export class PageTitleComponent {
  @Input() subtitle = false;

  @Input() badge!: string;

  @Input() caption!: string;

  @Input() backUrl!: string;

  @Input() icon!: Icon;

  @Input() hasHideMoney = false;

  @Input() showActionsOnTouch: boolean;

  @Input() captionFlexDirectionColumn: boolean;

  @Output() iconClick = new EventEmitter();

  isTouch$ = this.responsiveService.isTouch$;

  constructor(
    private readonly responsiveService: ResponsiveService,
    public readonly loadingService: LoadingService,
  ) {}
}
