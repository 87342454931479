import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { DigitPipe } from './digit.pipe';
import { DocumentIconPipe } from './document-icon.pipe';
import { PhoneFormatPipe } from './phone-format.pipe';
import { StateAbbrPipe } from './state-abbr.pipe';
import { DefaultValuePipe } from './default-value.pipe';
import { FileSizePipe } from './file-size.pipe';

@NgModule({
    exports: [
        DigitPipe,
        DocumentIconPipe,
        PhoneFormatPipe,
        StateAbbrPipe,
        DefaultValuePipe,
        FileSizePipe,
    ],
    imports: [CommonModule, DigitPipe,
        DocumentIconPipe,
        PhoneFormatPipe,
        StateAbbrPipe,
        FileSizePipe,
        DefaultValuePipe],
    providers: [DecimalPipe, CurrencyPipe],
})
export class PipeModule {}
