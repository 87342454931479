<a
  ec-link
  [class.ms-4]="withMargin()"
  (click)="toggleMoney()"
  [disabled]="(loadingService.loadingState$ | async) === true"
>
  <span
    color="blue"
    ec-icon-wrapper
    [icon]="!getShowMoney() ? 'show' : 'hide'"
    size="md"
  ></span>
  {{ !getShowMoney() ? 'Show Money' : 'Hide Money' }}
</a>
